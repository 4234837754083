class ScrollLinks {
	constructor() {
		this.init();
	}

	init() {
		var _this = this;
		var $links = {}
	
		$links = document.querySelectorAll('[data-scroll-link]');

		$links.forEach(($particle, i) => {
			var $this = $particle;

			$this.addEventListener('click', function(e) {
				e.preventDefault();

				_this.scrollTo($this.getAttribute('href'));
			});
		})
	}
	
	scrollTo(href) {
		var $target = document.querySelector(href);

		window.scrollTo({
			top: $target.getBoundingClientRect().top +  + document.documentElement.scrollTop - 50,
			behavior: 'smooth'
		});
	}
};

export default ScrollLinks