class ExternalLinks {
	constructor() {
		this.init();
	}

	init() {
		var _this = this;
		var $links = [];

		document.querySelectorAll('a').forEach(($link, i) => {
			let urlStr = $link.getAttribute('href');
			if(urlStr.startsWith('http')){
				let url = (new URL(urlStr));
				if(window.location.host.split(':')[0] != url.hostname) {
					$link.classList.add('external');
					$link.setAttribute('target', '_blank');
				}
			}
		})
	}
};

export default ExternalLinks