﻿import barba from '@barba/core';
import { gsap } from 'gsap';
import TradeFixtures from './app2'

class Transitions {
	constructor() {
		this.init();
	}

	init() {
		barba.init({
			debug: true,
			timeout: 10000,
			transitions: [{
			  name: 'default-transition',
			  once(data) {
					return gsap.set(data.next.container, {
						 opacity: 1
					});
			  },
			  leave(data) {
					return gsap.to(data.current.container, {
						 opacity: 0,
					  y: -40
					});
			  },
			  beforeEnter(data) {
					tradeFixtures.initPageBlocks(); 
				  	wpforms.init();
			  },
			  enter(data) {

				if(data.next.url.hash !== 'undefined') {
					setTimeout(() => {
						if(document.querySelectorAll('#' + data.next.url.hash).length > 0) {
							setTimeout(()=>{
								scrollLinks.scrollTo('#' + data.next.url.hash);
							},100)
						} else {
							scrollLinks.scrollTo('#top');
						}
					}, 500);
				}
				gsap.set(data.current.container, { display:'none' });
				return gsap.fromTo(data.next.container, {
					opacity: 0,
					y: 40
				},{
					opacity: 1,
					y: 0
				});
			  },
			  after(data) {
			  }
			}]
		})
	}
};

export default Transitions
