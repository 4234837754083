import Isotope from 'isotope-layout';
import axios from 'axios';
import Vue from 'vue';

class ProductGallery {
	constructor() {
		if(document.querySelectorAll('.product-gallery').length > 0) {
			this.init();
		}
	}

	init() {
		var _this = this;
		var gallery = document.querySelectorAll('.product-gallery')[0],
			panels = gallery.querySelector('.panels'),
			filters = gallery.querySelectorAll('.filter'),
			imgs = [],
			boards = ['north-america-bulk-sets','asia-pacific-bulk-sets','europe-bulk-sets','across-the-world-bulk-sets'];

		_this.getPins = new Promise((resolve,reject) => {
			let counter = 0,
				pins = [];

			for(var i=0;i<boards.length;i++) {
				const board = boards[i];
				axios.get('https://api.pinterest.com/v3/pidgets/boards/tradefixtures/' + board + '/pins/')
					.then(function (response) {
						var tempPins = response.data.data.pins;
						tempPins = tempPins.map((pin) => ({
							...pin,
							board: board
						}));
						pins.push(...tempPins);
						counter++;
						if(counter >= boards.length) {
							resolve(pins);
						}
					})
			}
		})
		
		_this.getPins.then((pins) => {
			app._data.pins.push(...pins);
		})

		filters.forEach(filter => {
			filter.onclick = () => {
				var oldFilter = typeof gallery.isotope.options.filter !== 'undefined' && gallery.isotope.options.filter != '' ? gallery.isotope.options.filter.split(',') : [];
				var newFilter = '.' + filter.dataset.filter;

				if(oldFilter.includes(newFilter)) {
					filter.classList.remove("active");
					oldFilter.splice(oldFilter.indexOf(newFilter),1);
				} else {
					filter.classList.add("active");
					oldFilter.push(newFilter);
				}
				gallery.isotope.arrange({ filter: oldFilter.join(',') })
			};
		});

		const app = new Vue({
			el: '#product-gallery',
			data: {
				pins: []
			},
			delimiters: ['[[',']]'],
			watch: {
				pins: function () {
				}
			},
			updated: function () {
				this.$nextTick(function () {
					gallery = document.querySelectorAll('.product-gallery')[0];
					panels = gallery.querySelector('.panels');
					imgs = this.$refs.img;

					var imagesLoaded = new Promise((resolve,reject) => {
						let counter = 0;
						imgs.forEach(img => {
							if(!img.complete) {
								img.onload = () => {
									counter++;
									if(counter >= imgs.length) {
										resolve()
									}
								};
							} else {
								counter++;
								if(counter >= imgs.length) {
									resolve()
								}
							}
						});
					})

					imagesLoaded.then(() => {
						setTimeout(() => {
							gallery.isotope = new Isotope( panels, {
								itemSelector: '.panel',
								layoutMode: 'masonry'
							});
						}, 100);
					})
				})
			}
		})
	}
};

export default ProductGallery