﻿import Vue from 'vue';
import axios from 'axios';
import ScrollLinks from './scroll-links'

class Resources {
	constructor() {
		this.init();
	}

	init() {
		var _this = this;

		var resourcesSearch = Vue.component('resources-search', {
			props: ['filteredCategories','searchQuery'],
			data: function () {
				return {
					searchQuery: this.searchQuery,
					resources: this.resources
				}
			},
			methods: {
				onSearchChanged() {
					const _this = this
					this.$emit('search-changed', this.searchQuery)

					return false;
				},

				clearSearch() {
					const _this = this
					_this.searchQuery = '';
					_this.onSearchChanged();
					setTimeout(()=>{
						window.scrollLinks = new ScrollLinks();
					},100);
				}
			},
			template: `
				<section class="resources-search">
					<h4 class="heading">Search</h4>
					<label for="resources-search">Search Keyword</label>
					<form v-on:submit="onSearchChanged()" v-on:submit.prevent>
						<div class="filter-input">
							<input id="resources-search" v-model="searchQuery" type="text" />
							<div class="clear" v-if="searchQuery != ''" @click="clearSearch()">&times;</div>
						</div>
						<button>Submit</button>
					</form>
				</section>
			`
		})

		var resourcesList = Vue.component('resources-list', {
			props: ['resources','filteredCategories','searchQuery'],
			data: function () {
				return {
					resources: this.resources,
					filteredCategories: this.filteredCategories,
					searchQuery: this.searchQuery,
				}
			},
			template: `
				<section class="resources-categories">
					<article class="category" v-for="category in filteredCategories" v-if="category.posts.length" :id="'category-' + category.slug"> 
						<h4 class="heading" v-html="category.name"></h4>
						<section class="resources">
							<article class="resource" v-for="resource in category.posts">
								<p class="title" v-html="resource.post_title"></p>
								<a :href="resource.file.url" class="download" data-barba-prevent target="_blank">Download</a>
							</article>
						</section>

						<section v-if="category.children.length > 0" class="subcategories">
							<article v-for="child in category.children" v-if="child.posts.length > 0" class="category"> 
								<h4>{{child.name}}</h4>
								<article class="resource" v-for="subChild in child.posts">
									<p class="title" v-html="subChild.post_title"></p>
									<a :href="subChild.get_field('file').url" class="download" data-barba-prevent target="_blank">Download</a>
								</article>
							</article>
						</section>
					</article>
				</section>
			`
		})

		var resourcesNav = Vue.component('resources-nav', {
			props: ['resources','filteredCategories'],
			data: function () {
				return {
					resources: this.resources,
					filteredCategories: this.filteredCategories
				}
			},
			template: `
			<nav class="nav sidebar-section">
				<h4 class="heading">Categories</h4>
				<ul>
					<li v-for="category in filteredCategories" v-if="category.posts.length > 0">
						<a :href="'#category-' + category.slug" class="nav-item" data-scroll-link v-html="category.name + '(' + category.posts.length + ')'" data-scroll-link></a>
					</li>
				</ul>
			</nav>
			`
		})

		const app = new Vue({
			el: '#resources-list',
			components: [resourcesSearch, resourcesList, resourcesNav],
			data: {
				resources: [],
				searchQuery: '',
			},
			mounted() {
				var _this = this;
				Promise.all([_this.getResources()])
					.then(function (results) {
						_this.resources = results[0].data;
						setTimeout(()=>{
							window.scrollLinks = new ScrollLinks();
						},100);
					})
			},
			methods: {
				getResources() {
					return axios.get(ajaxurl + '?action=TF_get_resources');
				},
				updateSearch (searchQuery) {
					this.searchQuery = searchQuery
					window.scrollLinks = new ScrollLinks();
				},
			},
			computed: {
				filteredCategories() {
					var _this = this;
					var filtered = [];
					
					_this.resources.forEach((category,i) => {
						filtered.push({name:category.name,slug:category.slug,children:[]});

						filtered[i].posts = category.posts.posts.filter(function(resource) {
							return resource.post_title.toLowerCase().includes(_this.searchQuery.toLowerCase()) || _this.searchQuery == '';
						});

						category.children.forEach((child,i) => {
							filtered[i].children.push({name:child.name,posts:[]});
							filtered[i].children.posts = category.children.posts.filter(function(resource) {
								return resource.post_title.includes(_this.searchQuery) || _this.searchQuery == '';
							});
						});
					});
					
					return filtered
				},
			},
		})
	}
};

export default Resources