﻿import Transitions from './transitions'
import Resources from './resources'
import ProductGallery from './product-gallery'
import PhotoGallery from './photo-gallery'
import ContactForms from './contact-forms'
import ScrollLinks from './scroll-links'
import ExternalLinks from './external-links'
import ContactModal from './contact-modal'
import Particles from './particles'
import Tables from './tables'

class TradeFixtures {
	constructor() {
		this.init();
	}

	init() {
		const transitions = new Transitions();
		const contactModal = new ContactModal();
		this.initPageBlocks()
	}

	initPageBlocks() {
		window.scrollLinks = new ScrollLinks();
		window.externalLinks = new ExternalLinks(); 
		window.resources = new Resources();
		window.contactForms = new ContactForms();
		window.productGallery = new ProductGallery();
		window.photoGallery = new PhotoGallery();
		window.tables = new Tables();

		if(typeof window.particleWatcher !== 'undefined') {
			cancelAnimationFrame(window.particleWatcher);
		}
		window.particles = new Particles();
	}
};

window.tradeFixtures = new TradeFixtures();

export default TradeFixtures