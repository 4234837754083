class Particles {
	constructor() {
		setTimeout(()=>{
			this.init();
		}, 500)
	}

	init() {
		var scrolling = false,
			initial = true;

		class Particle {
			constructor(options) {
				var _this = this;
				
				var colors = ['#9FC35F', '#789B4A', '#26551A', '#D5D447'];

				this.$el = options.$el;
				this.$particle = options.$particle;
				this.shape = options.shape ? options.shape : '';
				this.image = options.image ? options.image : null;
				this.color = options.color ? options.color : colors[Math.ceil(Math.random() * 4) - 1];
				this.left = (options.left && options.left != 0) ? options.left + '%' : _randomLeftInt() + '%';
				this.top = (options.top && options.top != 0) ? options.top + '%' : (Math.random() * 100) + '%';
				this.offsetTop = 0;
				this.addBlur = options.addBlur === 'false' ? false : true;

				this.speedScale = (options.speedScale && options.speedScale != 0) ? options.speedScale : Math.random() * .5;
				this.baseScale = (options.scale && options.scale != 0) ? options.scale*globalScale : Math.random() * globalScale * .25 + .25;
				//this.baseBlur = this.addBlur ? this.baseScale : 0;
				this.baseRotate = options.rotate ? parseInt(options.rotate) : Math.random() * 360;

				this.z = this.baseScale < .45 ? 3 : 10;

				this.mask = (function(){
					switch(_this.shape) {
						case 'leaf':
							return 'particle-1';
						case 'circle':
							return 'particle-2';
						case 'none':
							return '';
						default:
							return 'particle-2';
					}
				})();

				this.init();
			}

			init() {
				this.setInitialProps();
				this.setInitialCoords();
			}

			setInitialProps() {
				
				this.$particle.style.backgroundColor = this.color;
				this.$particle.style.backgroundImage = "url('" + this.image + "')";
				this.$particle.style.clipPath = "url('#" + this.mask + "')";
				this.$particle.style.transform = 'translateY(' + this.y + 'px) rotate(' + this.baseRotate + 'deg)';
				this.$el.style.left = this.left;
				this.$el.style.top = this.top;
				this.$el.style.transform = 'translateX(-50%) scale(' + this.baseScale + ')';
				this.$el.style.zIndex = this.z;
			}
			
			setInitialCoords() {
				this.box = this.$el.getBoundingClientRect();
			
				var body = document.body;
				var docEl = document.documentElement;
			
				_setScrollTop();
			
				this.top  = this.box.top + scrollTop;
			}
		}

		function _addEvents() {

			window.addEventListener('scroll', function(){
				if(this.scrollTimeout !== 'undefined') { clearTimeout(this.scrollTimeout); }
				scrolling = true;
				this.scrollTimeout = setTimeout(function() {
					scrolling = false;
				}, 100);
			});

			window.addEventListener('resize', function(){
				_onResize();
			});

		}

		function _watch() {
			var _this = this;
			window.particleWatcher = window.requestAnimationFrame(_watch.bind(this));
			
			if(scrolling || initial) {
				initial = false;
				_setScrollTop();
				_updateParticles()
			}

		}

		function _onResize() {
			particles.forEach((particle, i) => {
				particle.setInitialCoords()
			})
		}

		function _setScrollTop() {
			var body = document.body;
			var docEl = document.documentElement;
		
			scrollTop = (window.pageYOffset || docEl.scrollTop || body.scrollTop);
		}

		function _updateParticles() {
			particles.forEach((particle, i) => {
				particle.y = (scrollTop + screenOrigin - particle.top) * particle.speedScale;

				particle.$el.style.transform = 'translateX(-50%) scale(' + particle.baseScale + ')';
				particle.$el.style.filter = 'blur(' + particle.baseBlur + 'rem)';
				particle.$particle.style.transform = 'translateY(' + particle.y + 'px) rotate(' + particle.baseRotate + 'deg)';
			})
		}

		function _randomLeftInt () {
			var number = Math.random();

			return ((number * 2 - 1) * 2 + (Math.sign(number * 2 - 1) * 3) + 5) * 10;
		}
		
		var $particles = {},
			particles = [],
			scrollTop = 0,
			screenOrigin = 0,
			globalScale = 0;

		$particles = document.querySelectorAll('.particle-position');
		globalScale = .5;
		screenOrigin = window.innerHeight * .75;

		$particles.forEach(($particle, i) => {
			var $this = $particle;

			particles[i] = new Particle({
				$el: $this, 
				$particle: $particle.querySelector('.particle'), 
				shape: $this.dataset.shape, 
				color: $this.dataset.color,
				image: $this.dataset.image,
				left: $this.dataset.left,
				top: $this.dataset.top,
				scale: $this.dataset.scale,
				rotate: $this.dataset.rotate,
				speedScale: $this.dataset.speedscale,
				addBlur: $this.dataset.addblur || true
			})
		})

		_onResize();
		_addEvents();
		_watch();
		initial = true;
	}
};

export default Particles