import Vue from 'vue';

class ContactForms {
	constructor() {
		if(document.getElementById('contact-forms')) {
			this.init();
		}
	}

	init() {
		const app = new Vue({
			el: '#contact-forms',
			data: {
				activeForm: 'usa'
			},
			mounted: function () {
				this.activeForm = this.$refs.default.value;
			},
			delimiters: ['[[',']]'],
			methods: {
				onRegionChanged(data) {
					const _this = this
					this.activeForm = data.target.elements.region.value;
					wpforms.init();

					var $form = document.getElementById('contact-forms').getElementsByClassName( 'wpforms-form' )[0];
					$form.addEventListener( 'submit', function( ev ) {
						console.log($form);
						ev.preventDefault();

						grecaptcha.ready(function () {
							grecaptcha.execute('6LeokdcfAAAAAIFr3aEJjYdstwHiKrrbBQJsIiVB', { action: 'submit' }).then(function (token) {
								console.log(token);
								//$form.submit();
							});
						});
					});

					return false;
				}
			}
		})
	}
};

export default ContactForms