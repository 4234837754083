import { gsap } from 'gsap';

class ContactModal {
	constructor() {
		this.init();
	}

	init() {


		// PRIVATE VARS
		//~~~~~~~~~~~~~~~~~~~~~~~~~~~
		var $modal = {},
			$main = {},
			$close = {},
			$form = {},
			$submit = {},
			$overlay = {},
			showTimeOut,
			seenContact,
			showTimeOutTime = 10000; //time in ms
	
		// PRIVATE METHODS
		//~~~~~~~~~~~~~~~~~~~~~~~~~~~
		$modal = document.querySelector('[data-contact-modal]');
		$main = document.querySelector('[data-main]');
		$form = $modal.querySelector('.wpforms-form');
		$close = $modal.querySelector('[data-close]');
		$submit = $modal.querySelector('.wpforms-submit');
		$overlay = $modal.nextElementSibling;
		seenContact = getCookie('seenContact');

		if (!seenContact) {
			showTimeOut = setTimeout(()=>{
				_showModal();
			}, showTimeOutTime);
		}
	
		function _showModal() {
			$modal.classList.add('show');
			$modal.style.display = 'block';
			$main.classList.add('hide');
			gsap.set($overlay, {
				opacity: 0,
				display: 'block'
			});
			gsap.fromTo($overlay, {
				opacity: 0
			}, {
				opacity: 1,
				duration: .5
			});
			
			$close.addEventListener('click', ()=>{
				_hideModal();
			});
			$overlay.addEventListener('click', ()=>{
				_hideModal();
			});
			document.querySelector('body').addEventListener('click', ()=>{
				_hideModal();
			});
			$modal.addEventListener('click', (e)=>{
				e.stopPropagation();
			});
			document.addEventListener('keyup', (e)=>{
				if(e.code == 'Escape') {
					_hideModal();
				}
			});
			$submit.addEventListener('click', function() {
				setSeen();
			});
	
			const observer = new MutationObserver(onFormChange);
			observer.observe($form, { attributes: true, childList: true, subtree: true });
		}
		function _hideModal() {
			setSeen();
			$modal.classList.remove('show');
			setTimeout(()=>{
				$modal.style.display = 'none';
			},1000)

			$main.classList.remove('hide');
			gsap.to($overlay, {
				opacity: 0,
				duration: .5,
				onComplete: function() {
					gsap.set($overlay, {
						display: 'none'
					});
				}
			});
		}
	
		function setSeen() {
			setCookie('seenContact',true,30); //expires after 30 days
		}
		function setCookie(name,value,days) {
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + (value || "")  + expires + "; path=/";
		}
		function getCookie(name) {
			var nameEQ = name + "=";
			var ca = document.cookie.split(';');
			for(var i=0;i < ca.length;i++) {
				var c = ca[i];
				while (c.charAt(0)==' ') c = c.substring(1,c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
			}
			return null;
		}
	
		function onFormChange() {
			if($modal.querySelectorAll('.wpforms-confirmation-container').length) {
				showTimeOut = setTimeout(()=>{
					_hideModal();
				}, 10000);
			}
		}
	}
};

export default ContactModal