class Tables {
	constructor() {
		this.$tables = document.getElementsByClassName('models');
		this.init();
	}

	init() {

		class Table {
			constructor($table) {
				this.init($table);
			}

			init($table) {
				var _this = this;

				$table.addEventListener('scroll', ()=>{
					if($table.scrollLeft > 0) {
						$table.classList.add('scrolled');
					} else {
						$table.classList.remove('scrolled');
					}
				});

				window.addEventListener('resize', ()=>{
					_this.onResize($table);
				});
				_this.onResize($table);
			}

			onResize($table) {
				if($table.querySelectorAll('tbody')[0].offsetWidth > $table.offsetWidth) {
					$table.classList.add('fade');
				} else {
					$table.classList.remove('fade');
				}
			}
		}

		for(var $table of this.$tables) {
			new Table($table);
		}
	}
};

export default Tables