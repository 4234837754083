import Isotope from 'isotope-layout';
import axios from 'axios';
import Vue from 'vue';

class PhotoGallery {
	constructor() {
		if(document.querySelectorAll('.photo-gallery').length > 0) {
			this.galleries = document.querySelectorAll('.photo-gallery');
			this.init();
		}
	}

	init() {

		this.galleries.forEach(gallery => {
			var _this = this;

			var panels = gallery.querySelector('.panels'),
				imgs = gallery.querySelectorAll('img');

			const app = new Vue({
				el: '#photo-gallery',
				data: {
					pins: [],
					boards: 'usa'
				},
				delimiters: ['[[',']]'],
				watch: {
					pins: function () {
					}
				},
				mounted: function () {
					_this.boards = this.$refs.boards.dataset.value.split(',');
					
					_this.getPins = new Promise((resolve,reject) => {
						let counter = 0,
						pins = [];
						
						for(var i=0;i<_this.boards.length;i++) {
							const board = _this.boards[i];
							axios.get('https://api.pinterest.com/v3/pidgets/boards/tradefixtures/' + board + '/pins/')
								.then(function (response) {
									var tempPins = response.data.data.pins;
									tempPins = tempPins.map((pin) => ({
										...pin,
										board: board
									}));
									pins.push(...tempPins);
									counter++;
									if(counter >= _this.boards.length) {
										resolve(pins);
									}
								})
						}
					})
					
					_this.getPins.then((pins) => {
						app._data.pins.push(...pins);
					})
				},
				updated: function () {
					this.$nextTick(function () {
						gallery = document.querySelectorAll('.photo-gallery')[0];
						panels = gallery.querySelector('.panels');
						imgs = this.$refs.img;

						_this.imagesLoaded = new Promise((resolve,reject) => {
							let counter = 0;
							imgs.forEach(img => {
								if(!img.complete) {
									img.onload = () => {
										counter++;
										if(counter >= imgs.length) {
											resolve()
										}
									};
								} else {
									counter++;
									if(counter >= imgs.length) {
										resolve()
									}
								}
							});
						})
						
						_this.imagesLoaded.then(() => {
							gallery.isotope = new Isotope( panels, {
								itemSelector: '.panel',
								layoutMode: 'masonry'
							});
						})
					})
				}
			})
		});
	}
};

export default PhotoGallery